import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import {Button, Checkbox, Dropdown, Header, Icon, Input, Loader, Menu, Modal, Pagination, Popup, ButtonGroup,} from '../../../node_modules/semantic-ui-react';
import MessageBoxYesNo from '../../Commons/MessageBoxYesNo';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import PropTypes from 'prop-types';
import { ajaxPost } from '../../ajax';
import { fnSearchEmployee, isTaskAvailable, ajaxPostDownload, checkDateRange, handleNumeric, minDate, maxDate, checkDate } from '../../utils';
import SelectEmployees from '../../Commons/SelectEmployees';
import _ from 'lodash';
import update from 'immutability-helper';
import DataGrid from 'react-data-grid';
import modStyles from './TransactionModuleBase.module.css';

const fileDialog = require('file-dialog');
const moment = require('moment');
const allEmployeeAccessString = "VIEW_ALL_EMPLOYEES";
var delayTimer = null;
var statusOptionsGlobal = [];

class TransactionModuleBase extends Component {
    constructor(props) {
        super(props);
        window.addEventListener('resize', this.handleResize);
        this.handleResize = this.handleResize.bind(this);

        if (this.props.props.prevState !== undefined) {
            this.state = this.props.props.prevState;
        }
        else {
            const monthData = moment().format("YYYY-MM");
            const daysInMonth = moment(monthData, "YYYY-MM").daysInMonth();
            const defaultStart = startOfMonth = moment(monthData + "-01").format("YYYY-MM-DD");
            const defaultEnd = monthData + "-" + daysInMonth;

            var startOfMonth = props.startDate == "0001-01-01T00:00:00" ? defaultStart : moment(props.startDate).format("YYYY-MM-DD");
            var endOfMonth = props.endDate == "0001-01-01T00:00:00" ? defaultEnd : moment(props.endDate).format("YYYY-MM-DD");

            var statusOptions = [];
            if (this.isNullOrEmpty(this.props.hasStatusField) ? true : this.props.hasStatusField) {
                if (isTaskAvailable(this.props.userRightsKeys.approve)) {
                    statusOptions.push({ text: "APPROVED", value: "APPROVED", key: "APPROVED" });
                }
                if (isTaskAvailable(this.props.userRightsKeys.reject)) {
                    statusOptions.push({ text: "REJECTED", value: "REJECTED", key: "REJECTED" })
                }
                if (isTaskAvailable(this.props.userRightsKeys.cancel)) {
                    statusOptions.push({ text: "CANCELLED", value: "CANCELLED", key: "CANCELLED" })
                }
            }
            statusOptionsGlobal = statusOptions;

            this.state = {
                userId: null,
                hasHrAccess: isTaskAvailable(allEmployeeAccessString),
                selectedStatus: "PENDING",
                hasStatusField: this.isNullOrEmpty(this.props.hasStatusField) ? true : this.props.hasStatusField,

                cellWidth: 100,
                checkBoxCellWidth: 20,
                actionCellWidth: 100,
                scrollBarAllowance: 17, // approx. width of scrollbar in Table (may vary on browsers)

                isLoading: true,
                isSaving: false,

                activePage: 1,
                totalPages: 0,
                itemsPerPage: 20,
                gridHeight: window.innerHeight - 250,

                fromDate: startOfMonth,
                toDate: endOfMonth,
                validFromDate: startOfMonth,
                validToDate: endOfMonth,

                rows: [],
                selectedRow: {},
                selectedRowIds: new Set(),
                selectedIndices: [],

                showNewButton: true,
                showDeleteButton: false,
                showRestoreButton: false,
                showEditButton: false,
                showApproveButton: false,
                showRejectButton: false,
                showCancelButton: false,
                showChangeStatusButton: false,
                showImportButton: false,
                showExportButton: false,

                hideSearchToolbar: false,

                employeeList: [],
                selectedEmployees: [],

                showRestoreModal: false,
                showDeleteModal: false,
                showCancelModal: false,

                showFiltersModal: false,
                showPending: true,
                showApproved: false,
                showRejected: false,
                showCancelled: false,
                includeDeleted: false,
                filterPrevState: {
                    showPending: true,
                    showApproved: false,
                    showRejected: false,
                    showCancelled: false,
                    includeDeleted: false
                },

                allCount: 0,
                pendingCount: 0,
                approvedCount: 0,
                cancelledCount: 0,
                rejectedCount: 0,

                activeStatus: "pending",
                approvedOTHours: 0,
                approverRemarks: "",
                statusOptions: statusOptions,

                messageBoxOkOnly: {
                    show: false,
                    title: "",
                    caption: "",
                },
                isMobileView: this.isMobileView(),
                showRequiredField: false,
                errTitle: "",
                errCaption: "",
            };

            this.search();
        }

    };

    onRowsSelected = (rows) => {
        var mappedIndeces = this.state.rows.map((x, i) => {
            return {
                Id: x.id, Index: i
            }
        });
        var selected = Array.from(rows);
        var selectedIndices = mappedIndeces.filter(x => selected.includes(x.Id)).map( x=> x.Index);
        this.setState({ selectedRowIds: rows, selectedIndices },
            () => { this.updateButtonsVisibility(); }
        );
    };
    /*
        onRowsDeselected = (rows) => {
            let rowIndexes = rows.map(r => r.rowIdx);
            let selectedIndices = this.state.selectedIndices.filter(i => rowIndexes.indexOf(i) === -1);
            let selectedRowIds = this.state.selectedRowIds.filter(id => rows.map(r => r.row.id).indexOf(id) === -1);
    
            this.setState(
                {
                    selectedIndices, selectedRowIds
                },
                function () {
                    this.updateButtonsVisibility();
                }
            );
        };
    */

    handleGridRowsUpdated = ({ action, fromRow, toRow, updated }) => {
        let rows = this.state.rows.slice();
        let rowsToAdd = this.state.rowsToAdd.slice();
        for (let i = fromRow; i <= toRow; i++) {
            let rowToUpdate = rows[i];
            if (rowToUpdate.editable) {
                if (action === "CELL_DRAG" || action === "COLUMN_FILL") {
                    if (updated.hasOwnProperty("employee")) { // if employee (name) is being dragged
                        var employeeId = this.state.rowPrevClicked.employeeId;
                        if (rowToUpdate.employeeId !== employeeId) {
                            rowToUpdate.employeeId = employeeId;
                        } else { continue; }
                    }
                }
                let updatedRow = update(rowToUpdate, { $merge: updated });
                rows[i] = updatedRow;
                if (this.state.mode === "CREATE") {
                    rowsToAdd[i] = updatedRow;
                }
            }
        }
        if (this.state.mode === "CREATE") { this.setState({ rowsToAdd: rowsToAdd }); }
        this.setState({ rows: rows });
    };

    isMobileView() {
        var isMobile = window.innerWidth <= 760;
        return isMobile;
    }

    getData = (input, callback) => {
        fnSearchEmployee(input, data => {
            var emps = [];
            data.content.map(item => {
                emps.push({
                    key: item._id,
                    FullName: item.LastName + ', ' + item.FirstName + ' ' + item.MiddleName + " (" + item.EmployeeNo + ")", EmployeeNo: item.EmployeeNo, AccessNumber: item.AccessNumber
                });
            })
            //this.setState({ subordinates: data.content });
            callback(null, {
                options: emps
            });
        }, 0, 10, false);
    }

    getUserId = () => {
        this.setState({ userId: global.userId });
        // var parameter = {
        //     data: {},
        //     url: "api/TransactionsCommon/getUserId",
        //     onSuccess: (data, sender) => {
        //         this.setState({ userId: data });
        //     },
        //     onError: (error) => {
        //         let errorMessage = "An error has occured.";
        //         if (error) {
        //             errorMessage += "\r\n" + JSON.stringify(error);
        //         }
        //         this.messageBoxOkOnlyOpen(true, "Error", errorMessage);
        //     },
        //     finally: () => {

        //     }
        // }
        // ajaxPost(parameter);
    }

    componentDidMount() {
        this.getUserId();
        this.handleResize();
    }

    componentWillUnmount() {
        clearInterval(this.statusCounterInterval);
        this.props.props.unmountCallback(this.state, this.props.viewID);
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ isMobileView: this.isMobileView() });
        //return;
        if (document.getElementById("table") === null) {
            return;
        }
        let headerMargin = 50;
        let footerMargin = 35;
        let gridTop = (document.getElementById("table")).offsetTop;
        var gridHeight = window.innerHeight - (headerMargin + gridTop + footerMargin);
        //var scrollBarAllowance = document.getElementById("tableBody").scrollHeight > document.getElementById("tableBody").offsetHeight ? 17 : 0;
        //var cellWidth = ((document.getElementById("table") === null ? 1500 : document.getElementById("table").offsetWidth) - (this.state.checkBoxCellWidth + this.state.actionCellWidth)) / this.props.columns.length;
        this.setState({
            gridHeight,
            //cellWidth, scrollBarAllowance 
        });
    }

    updateButtonsVisibility = (optionalMethod = () => { }) => {
        var hasHrAccess = this.state.hasHrAccess;
        var hasStatusField = this.state.hasStatusField;
        let userId = this.state.userId;
        let deletableItemsCount = 0;
        //let ownRecordsCount = 0;
        let cancellableRecordsCount = 0;
        let approvableRecordsCount = 0;
        let rejectableRecordsCount = 0;
        let changeableStatusRecordsCount = 0;
        let selectedRows = [];
        let selectedRowIds = this.state.selectedRowIds;
        //let updatedRows = [];

        if (selectedRowIds.size > 0) {
            selectedRowIds.forEach((rowId, index) => {
                selectedRows.push(this.state.rows.filter(row => row.id === rowId)[0]);
            });

            // if (selectedRowIds.length === 1) {
            //     // set idle
            //     this.state.rows.forEach(row => {
            //         if(row.status === selectedRows[0].status) {
            //             row.idle = false;
            //         }
            //         else {
            //             row.idle = true;
            //         }
            //         updatedRows.push(row);
            //     });
            //     this.setState({ rows: updatedRows });
            // }

            if (hasHrAccess) {
                deletableItemsCount = selectedRows.filter(function (item) { return !item.deleted && (item.status === "PENDING" || !hasStatusField) }).length;
                cancellableRecordsCount = selectedRows.filter(function (item) { return !item.deleted && (item.status !== "CANCELLED") }).length;
                changeableStatusRecordsCount = selectedRows.filter(function (item) { return !item.deleted && item.status !== "CANCELLED" }).length;
                approvableRecordsCount = selectedRows.filter(function (item) { return !item.deleted && item.status !== "CANCELLED" && item.status !== "APPROVED" }).length;
                rejectableRecordsCount = selectedRows.filter(function (item) { return !item.deleted && item.status !== "CANCELLED" && item.status !== "REJECTED" }).length;
            }
            else {
                deletableItemsCount = selectedRows.filter(function (item) { return !item.deleted && (item.status === "PENDING" || !hasStatusField) && item.employeeId === userId }).length;
                cancellableRecordsCount = selectedRows.filter(function (item) {
                    return !item.deleted &&
                        ((item.status === "PENDING" && item.employeeId === userId) ||
                            (item.status === "APPROVED" && item.employeeId !== userId) ||
                            (item.status === "REJECTED" && item.employeeId !== userId))
                }).length;
                changeableStatusRecordsCount = selectedRows.filter(function (item) { return !item.deleted && item.status !== "CANCELLED" && item.employeeId !== userId }).length;
                approvableRecordsCount = selectedRows.filter(function (item) { return item.status !== "APPROVED" && !item.deleted && item.status !== "CANCELLED" && item.employeeId !== userId }).length;
                rejectableRecordsCount = selectedRows.filter(function (item) { return item.status !== "REJECTED" && !item.deleted && item.status !== "CANCELLED" && item.employeeId !== userId }).length;
            }
        }

        //this.grid.selectAllCheckbox.checked = this.state.rows.length > 0 && this.state.rows.length == this.state.selectedRowIds.length;

        var statusOptions = statusOptionsGlobal;
        statusOptions = cancellableRecordsCount > 0 ? statusOptions : statusOptions.filter(x => x.value != "CANCELLED");
        statusOptions = approvableRecordsCount > 0 ? statusOptions : statusOptions.filter(x => x.value != "APPROVED");
        statusOptions = rejectableRecordsCount > 0 ? statusOptions : statusOptions.filter(x => x.value != "REJECTED");

        this.setState({
            showApproveButton: approvableRecordsCount > 0,
            showRejectButton: rejectableRecordsCount > 0,
            showCancelButton: cancellableRecordsCount > 0,
            statusOptions,
            showNewButton: true,
            showChangeStatusButton: this.state.selectedRowIds.size > 0 && (changeableStatusRecordsCount > 0 || cancellableRecordsCount > 0),
            showDeleteButton: this.state.selectedRowIds.size > 0 && deletableItemsCount > 0,
            showEditButton: isTaskAvailable(this.props.userRightsKeys.edit) && this.state.selectedRowIds.size === 1 && (!this.state.hasStatusField || selectedRows[0].status === "PENDING") && (userId === selectedRows[0].employeeId || hasHrAccess),
            //showExportButton: false,
            hideSearchToolbar: false,
        },
            optionalMethod
        );
    };

    search = () => {
        var fromDate = this.state.fromDate;
        var toDate = this.state.toDate;
        var checkDateParams = checkDateRange(fromDate, toDate)
        if (!checkDateParams.Result) {
            this.messageBoxOkOnlyOpen(true, checkDateParams.Title, checkDateParams.Message);
            fromDate = this.state.validFromDate;
            toDate = this.state.validToDate;
            this.setState({ fromDate, toDate });
            return;
        }
        else {
            this.setState({ validFromDate: fromDate, validToDate: toDate });
        }

        let employeeIds = [];
        employeeIds = this.state.selectedEmployees.slice();
        var statuses = [];
        if (this.state.showPending) { statuses.push("PENDING") };
        if (this.state.showApproved) { statuses.push("APPROVED") };
        if (this.state.showCancelled) { statuses.push("CANCELLED") };
        if (this.state.showRejected) { statuses.push("REJECTED") };

        this.setState({ isLoading: true });
        this.setState({ activeStatus: statuses.length == 4 ? "all" : statuses.length == 1 ? statuses[0].toLowerCase() : "none" });

        var parameter = {
            fromDate: moment(fromDate).toJSON(),
            toDate: moment(toDate).toJSON(),
            employeeIds: employeeIds,
            statuses: statuses,
            includeDeleted: this.state.includeDeleted,
            startIndex: (this.state.activePage - 1) * this.state.itemsPerPage,
            itemCount: this.state.itemsPerPage
        };
        var searchParameter = {
            data: parameter,
            url: this.props.urls.search,
            onError: (error) => {
                var errMessage = "An error has occured";
                if (error) {
                    errMessage += "\r\n" + JSON.stringify(error);
                }
                this.messageBoxOkOnlyOpen(true, "Error", errMessage);
            },
            onSuccess: (data, sender) => {
                var rows = data.content.map((model, index, array) => { return this.props.toRow(model) });
                var totalPages = data.count / this.state.itemsPerPage;
                totalPages -= totalPages % 1;
                if (data.count > totalPages * this.state.itemsPerPage) {
                    totalPages++;
                }
                this.setState(
                    {
                        rows: rows,
                        totalPages: totalPages,
                        selectedIndices: [],
                        selectedRowIds: new Set(),
                    },
                    () => { this.updateButtonsVisibility(); }
                );
            },
            finally: () => {
                this.setState({ isLoading: false });
            }
        };
        ajaxPost(searchParameter);
    }

    handleStatusClick = (e, { name }) => {
        this.setState(
            {
                activePage: 1,
                activeStatus: name,
                showPending: name === 'pending' || name === 'all',
                showApproved: name === 'approved' || name === 'all',
                showCancelled: name === 'cancelled' || name === 'all',
                showRejected: name === 'rejected' || name === 'all',
            },
            () => this.search()
        );
    }

    messageBoxOkOnlyOpen = (isOpen, title = "", caption = "") => {
        var messageBoxOkOnly = {
            show: isOpen,
            title: title,
            caption: caption,
        };
        this.setState({ messageBoxOkOnly });
    }
    /*
        onCheckBoxHeader_Checked = (event, data) => {
            var checked = data.checked;
    
            var selectedRowIds = [];
            if (checked) {
                this.state.rows.forEach(row => {
                    let rowId = row.id;
                    selectedRowIds = selectedRowIds.concat(rowId);
                });
            }
    
            this.setState({ selectedRowIds: selectedRowIds },
                () => { this.updateButtonsVisibility() }
            );
        }
    
        onCheckBoxHeaderEmp_Checked = (event, data) => {
            var checked = data.checked;
            var employeeId = data.taggedId;
            var selectedRowIds = this.state.selectedRowIds;
            this.state.rows.filter(row => row.employeeId === employeeId).forEach(row => {
                let rowId = row.id;
                if (checked) {
                    if (selectedRowIds.indexOf(rowId) === -1) selectedRowIds = selectedRowIds.concat(rowId);
                }
                else {
                    selectedRowIds = selectedRowIds.filter(e => e !== rowId);
                }
            });
    
            if (selectedRowIds.length > 0) {
                this.setState({
                    selectedIndices:
                        this.state.rows
                            .filter(row => selectedRowIds.indexOf(row.id) !== -1)
                            .map((row) => this.state.rows.findIndex(r => r.id === row.id))
                });
            }
    
            this.setState({ selectedRowIds: selectedRowIds },
                () => { this.updateButtonsVisibility() }
            );
        }
    
        onCheckBox_Checked = (event, data) => {
            var checked = data.checked;
            var rowId = data.taggedId;
            var selectedRowIds = this.state.selectedRowIds;
            if (checked && (rowId !== null || rowId !== undefined)) {
                selectedRowIds = selectedRowIds.concat(rowId);
            }
            else {
                selectedRowIds = selectedRowIds.filter(e => e !== rowId);
            }
    
            if (selectedRowIds.length > 0) {
                this.setState({
                    selectedIndices:
                        this.state.rows
                            .filter(row => selectedRowIds.indexOf(row.id) !== -1)
                            .map((row) => this.state.rows.findIndex(r => r.id === row.id))
                });
            }
    
            this.setState({ selectedRowIds: selectedRowIds },
                () => { this.updateButtonsVisibility() }
            );
        }
    */
    employeeSearchChange = (event, data) => {
        var self = this;
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
            var employeeSearchParameter = {
                data: { needle: data.searchQuery === undefined ? "" : data.searchQuery, itemCount: 20 },
                url: this.props.urls.searchEmployees,
                onSuccess: (data, sender) => {
                    var newOptions = data.map((model, index, array) => { return { key: model._id, text: model.LastName + ", " + model.FirstName + " " + model.MiddleName, value: model._id } });
                    self.setState({ employeeList: newOptions });
                },
                onError: (error) => {

                },
                finally: () => { }
            };
            ajaxPost(employeeSearchParameter);
        }, 300);
    };

    btnSearch_click = () => {
        this.setState({ activePage: 1 }, () => { this.search() });
    };

    btnAdd_click = () => {
        var requestParameter = {
            data: { "_id": "" },
            url: this.props.urls.load,
            onSuccess: (data, sender) => {
                if (data.Employee === undefined || data.Employee === null) data.Employee = data.name;
                this.setState({ showRequiredField: false },
                    () => { this.props.showModal(data, true); }
                );
            },
            onError: (error) => {
                this.messageBoxOkOnlyOpen(true, "Error", "Failed loading new record.")
            },
            finally: () => { }
        };
        ajaxPost(requestParameter);
    }

    editFromModal = (data) => {
        let isValid = false;
        isValid = this.props.validate(data);
        if (!isValid) {
            return;
        }

        this.setState({ isSaving: true });
        let items = [];
        let employeeIds = data.employeeIds.slice();
        data.employeeIds = undefined;
        employeeIds.forEach((item, index) => {
            let dataClone = Object.assign({}, data);
            dataClone.EmployeeId = item;
            items.push(dataClone);
        });

        var parameter = {
            data: { items: items },
            url: this.props.urls.saveMultiple,
            onSuccess: (data, sender) => {
                if (data[data.length - 1].hasOwnProperty("errMessage")) {
                    let lastIndex = data.length - 1;
                    isValid = false;
                    //this.messageBoxOkOnlyOpen(true, data[lastIndex]["errTitle"], data[lastIndex]["errMessage"]);                    
                    this.setState({ showRequiredField: true, errTitle: data[lastIndex]["errTitle"], errCaption: data[lastIndex]["errMessage"] }, () => {
                        this.messageBoxOkOnlyOpen(true, data[lastIndex]["errTitle"], data[lastIndex]["errMessage"]);
                    });
                    return;
                }
                let tableRow = this.props.toRow(data[0]);
                let rows = this.state.rows.slice();
                let targetIndex = -1;
                rows.forEach((item, index) => {
                    if (item.id === tableRow.id) {
                        targetIndex = index;
                    }
                })
                rows[targetIndex] = tableRow;
                this.setState({ rows: rows },
                    () => { this.messageBoxOkOnlyOpen(true, "Success", "Record updated"); }
                );
            },
            onError: (error) => {
                isValid = false;
                var errorMessage = "An error has occured";
                if (error) {
                    errorMessage += ":\r\n" + JSON.stringify(error);
                }
                this.setState({ showRequiredField: true, errTitle: "Error", errCaption: errorMessage });
                this.messageBoxOkOnlyOpen(true, "Error", errorMessage);
            },
            finally: () => {
                this.setState({ isSaving: false });
                // close modal 
                if (isValid) this.props.showModal({}, false);
            }
        }
        ajaxPost(parameter);
    }

    saveFromModal = (data) => {
        let self = this;
        let isValid = false;
        let isRequestByHR = this.state.hasStatusField && isTaskAvailable(this.props.userRightsKeys.approve) && this.state.hasHrAccess;
        isValid = this.props.validate(data);
        if (!isValid) {
            return;
        }
        this.setState({ isSaving: true });
        data.Status = "PENDING";

        let items = [];
        let employeeIds = data.employeeIds.slice();
        data.employeeIds = undefined;
        employeeIds.forEach((item, index) => {
            let dataClone = Object.assign({}, data);
            dataClone.EmployeeId = item;
            // if(isRequestByHR) {
            //     dataClone.Status =  "APPROVED";
            // }
            items.push(dataClone);
        });

        var parameter = {
            data: { items: items },
            url: this.props.urls.saveMultiple,
            onSuccess: (data, sender) => {
                if (data[data.length - 1].hasOwnProperty("errMessage")) {
                    let lastIndex = data.length - 1;
                    isValid = false;
                    this.setState({ showRequiredField: true, errTitle: data[lastIndex]["errTitle"], errCaption: data[lastIndex]["errMessage"] }, () => {
                        this.messageBoxOkOnlyOpen(true, data[lastIndex]["errTitle"], data[lastIndex]["errMessage"]);
                    });
                    return;
                }
                isValid = true;

                let tableRows = data.map(item => { return self.props.toRow(item); });
                let rows = self.state.rows.slice();
                tableRows.forEach((item, index) => {
                    // let rowIndex = rows.findIndex(row => row.employeeId === item.employeeId);
                    // if (rowIndex === -1) rows.unshift(item);
                    // else rows.splice(rowIndex, 0, item);
                    rows.unshift(item);
                });

                self.setState({
                    rows: rows,
                    selectedRowIds: new Set(),
                    selectedIndices: [],
                },
                    () => {
                        self.messageBoxOkOnlyOpen(true, "Success", "Your request has been submitted"
                            // (isRequestByHR ? "Your request has been automatically approved" : "Your request has been submitted" )
                        )
                    }
                );
            },
            onError: (error) => {
                isValid = false;
                var errorMessage = "An error has occured";
                if (error) {
                    errorMessage += ":\r\n" + JSON.stringify(error);
                }
                this.setState({ showRequiredField: true, errTitle: "Error", errCaption: errorMessage }, () => {

                    this.messageBoxOkOnlyOpen(true, "Error", errorMessage);
                });
            },
            finally: () => {
                this.setState({ isSaving: false });
                // close modal 
                if (isValid) self.props.showModal({}, false);
            }
        }
        ajaxPost(parameter);
    }

    btnEdit_Click = (row, event, { target }) => {
        //var ownerId = row.employeeId;
        var rowId = row.id;
        var hasHrAccess = this.state.hasHrAccess;
        // if (ownerId !== this.state.userId && !hasHrAccess) {
        //     alert("You can only edit your own records.");
        //     return;
        // }
        let status = row.status;
        if (status === "APPROVED") {
            this.messageBoxOkOnlyOpen(true, "Invalid", "This record is already approved");
            return;
        }
        var requestParameter = {
            data: { "_id": rowId },
            url: this.props.urls.load,
            onSuccess: (data, sender) => {
                var dataRow = data !== null ? this.props.toRow(data) : {};
                if (dataRow.status === 'APPROVED' || dataRow.status === "REJECTED" || dataRow.status === "CANCELLED") {
                    this.messageBoxOkOnlyOpen(true, "Failed", "Unable to edit. \nThis record has been already " + dataRow.status.toLowerCase() + ".");
                    this.search();
                    return;
                }
                else if (dataRow.deleted === true) {
                    this.messageBoxOkOnlyOpen(true, "Failed", "Unable to edit. \nThis record no longer exists or has been deleted.");
                    this.search();
                    return;
                }
                data["Employee"] = row.employee;
                data["userId"] = this.state.userId;
                this.props.showModal(data, true);
            },
            onError: (error) => {
                this.messageBoxOkOnlyOpen(true, "Error", "Failed loading record");
            },
            finally: () => { }
        };
        ajaxPost(requestParameter);
    };

    btnDelete_Click = (row, event) => {
        let hasHrAccess = isTaskAvailable("VIEW_ALL_EMPLOYEES");
        var ownerId = row.employeeId;
        let rowId = row.id;
        // if (ownerId !== this.state.userId && !hasHrAccess) {
        //     alert("You can only delete your own records");
        //     return;
        // }
        var requestParameter = {
            data: { "_id": rowId },
            url: this.props.urls.load,
            onSuccess: (data, sender) => {
                if (data.Status === 'APPROVED') {
                    this.messageBoxOkOnlyOpen(true, "Failed", "Unable to delete. \nThis record has been already approved");
                    this.search();
                    return;
                }
                else {
                    this.setState({
                        showDeleteModal: !row.deleted,
                        showRestoreModal: row.deleted,
                        selectedRow: row
                    });
                }
            },
            onError: (error) => {
                this.messageBoxOkOnlyOpen(true, "Error", "Failed loading record")
            },
            finally: () => { }
        };
        ajaxPost(requestParameter);
    }

    btnCancel_Click = (row, event) => {
        var ownerId = row.employeeId;
        let rowId = row.id;
        var requestParameter = {
            data: { "_id": rowId },
            url: this.props.urls.load,
            onSuccess: (data, sender) => {
                if (data.Status !== 'PENDING') {
                    this.messageBoxOkOnlyOpen(true, "Failed", "Unable to cancel. \nThis record has been already " + data.Status.toLowerCase());
                    this.search();
                    return;
                }
                else {
                    this.setState({ showCancelModal: true, selectedRow: row, selectedRowIds: [rowId] });
                }
            },
            onError: (error) => {
                this.messageBoxOkOnlyOpen(true, "Error", "Failed while trying to cancel request");
            },
            finally: () => { }
        };
        ajaxPost(requestParameter);
    }

    setDeleted = (deleted) => {
        var self = this;
        let ids = [];
        let selectedRows = [];
        let deletableItems = [];
        let hasHrAccess = this.state.hasHrAccess;
        let hasStatusField = this.state.hasStatusField;
        let userId = this.state.userId;

        this.state.selectedRowIds.forEach((rowId) => {
            selectedRows.push(this.state.rows.filter(row => row.id === rowId)[0]);
        });

        if (hasHrAccess) {
            deletableItems = selectedRows.filter(function (item) { return !item.deleted && (item.status === "PENDING" || !hasStatusField) });
        }
        else {
            deletableItems = selectedRows.filter(function (item) { return !item.deleted && (item.status === "PENDING" || !hasStatusField) && item.employeeId === userId });
            //deletableItems = selectedRows.filter(function (item) { return !item.deleted && (item.status === "PENDING") && item.employeeId === userId });
        }

        deletableItems.forEach(item => {
            ids.push(item.id);
        });

        //ids = this.state.selectedRowIds;
        var parameter = {
            data: {
                ids: ids,
                deleted: deleted
            },
            url: self.props.urls.setDeleted,
            onError: function (error) {
                var errMessage = "An error has occured";
                if (error) {
                    errMessage += "\r\n" + JSON.stringify(error);
                }
                self.setState({ showRestoreModal: false, showDeleteModal: false },
                    () => { self.messageBoxOkOnlyOpen(true, "Error", errMessage); }
                );
            },
            onSuccess: (data, sender) => {
                if (data.hasOwnProperty("errors") && data.errors.length > 0) {
                    self.setState({ showRestoreModal: false, showDeleteModal: false },
                        () => { self.messageBoxOkOnlyOpen(true, "Error", data.errors.map(err => { return (<p>{err}</p>) })); }
                    );
                }
                else if (data.hasOwnProperty("content") && data.content.IsSuccessful) {
                    var rows = self.state.rows.slice();
                    deletableItems.forEach(item => {
                        var rowIndex = rows.findIndex((r => r.id === item.id));
                        rows[rowIndex].deleted = deleted;
                        rows = rows.filter(row => row.id != item.id).slice();
                    });
                    self.setState({
                        rows: rows,
                        selectedRow: {},
                        selectedRowIds: new Set(),
                        selectedIndices: [],
                        showRestoreModal: false,
                        showDeleteModal: false
                    },
                        function () { self.updateButtonsVisibility() }
                    );
                }
            },
            finally: function () { }
        };
        ajaxPost(parameter);
    };

    setCancelled = (row) => {
        let selectedRowIds = [];
        selectedRowIds[0] = this.state.selectedRow.id;
        this.setState({ selectedRowIds, selectedStatus: "CANCELLED", showCancelModal: false }, () => { this.onChangeStatus(); });
    };

    btnRestore_Click = (event, { item }) => {
        let hasHrAccess = isTaskAvailable("VIEW_ALL_EMPLOYEES");
        if (!hasHrAccess) {
            var selectedIndices = this.state.selectedIndices;
            var hasOtherRecords = false;
            selectedIndices.forEach((item, index) => {
                if (this.state.rows[item].employeeId !== this.state.userId) {
                    hasOtherRecords = true;
                }
            });
            if (hasOtherRecords) {
                this.messageBoxOkOnlyOpen(true, "Failed", "You can only restore your own records");
                return;
            }
        }
        this.setState({ showRestoreModal: true });
    }

    handlePageChange(event, { activePage }) {
        var self = this;
        if (activePage === this.state.activePage || activePage < 1) return;
        var page = activePage - (activePage % 1);
        if (page < activePage) {
            page++;
        }
        this.setState({ activePage: page }, function () {
            this.search();
        });;
    }

    btnChangeStatus_Click = (event, data) => {
        if (this.state.selectedRowIds.size === 0) {
            return;
        }

        //var status = this.state.selectedStatus;
        var status = data.taggedStatus;

        var statusOptions = this.state.statusOptions.slice();
        if (statusOptions.filter(item => item.value === status).length === 0) {
            status = statusOptions[0].value;
        }

        this.setState({ selectedStatus: status, statusFormVisible: true });
    }

    onChangeStatus = () => {
        if (this.state.selectedRowIds.size === 0) {
            return;
        }

        let self = this;
        let userId = this.state.userId;
        let hasHrAccess = this.state.hasHrAccess;
        let items = [];
        let selectedRowIds = this.state.selectedRowIds;
        let status = this.state.selectedStatus;
        let remarks = this.state.approverRemarks;
        let selectedRows = [];
        let isValid = false;
        let errMessage = "";
        selectedRowIds.forEach(rowId => {
            selectedRows.push(this.state.rows.filter(row => row.id === rowId)[0]);
        });

        let changeableStatusRecords = [];

        if (hasHrAccess) {
            if (status === "CANCELLED") changeableStatusRecords = selectedRows.filter(function (item) { return !item.deleted && (item.status !== "CANCELLED") });
            else changeableStatusRecords = selectedRows.filter(function (item) { return !item.deleted && item.status !== "CANCELLED" });
        }
        else {
            if (status === "CANCELLED") changeableStatusRecords = selectedRows.filter(function (item) {
                return !item.deleted &&
                    ((item.status === "PENDING" && item.employeeId === userId) ||
                        (item.status === "APPROVED" && item.employeeId !== userId) ||
                        (item.status === "REJECTED" && item.employeeId !== userId))
            });
            else changeableStatusRecords = selectedRows.filter(function (item) { return !item.deleted && item.status !== "CANCELLED" && item.employeeId !== userId });
        }

        changeableStatusRecords.forEach(record => {
            //items.push({ _id: record.id });
            items.push(this.props.toModel(record))
        });

        //if (status == "REJECTED") changeableStatusRecords = changeableStatusRecords.filter(function (item) { return item.status !== "REJECTED" })
        //if(status == "APPROVED") this.props.checkDuplicate();
        changeableStatusRecords = changeableStatusRecords.filter(function (item) { return item.status !== status })

        var dataParams = {
            items: items,
            status: status,
            remarks: remarks,
            fromDate: moment(this.state.validFromDate).toJSON(),
            toDate: moment(this.state.validToDate).toJSON(),
        };
        if (this.props.hasApprovedOTField && status === "APPROVED") {
            let approvedOTHours = this.state.approvedOTHours;
            dataParams.approvedOTHours = approvedOTHours || 0;
        }

        var parameter = {
            data: dataParams,
            url: this.props.urls.updateStatus,
            onError: function (error) {
                this.messageBoxOkOnlyOpen(true, "Error", "An error has occured");
            },
            onSuccess: (data, sender) => {
                if (data.length === 0) return;

                // if (data[data.length - 1].hasOwnProperty("errMessage")) {
                //     let lastIndex = data.length - 1;
                //     this.messageBoxOkOnlyOpen(true, data[lastIndex]["errTitle"], data[lastIndex]["errMessage"]);
                //     return;
                // }
                var message = data[data.length - 1];
                var c = data.splice(data.length - 1, 1);

                let rows = this.state.rows.slice();
                data.forEach(item => {
                    var rowIndex = rows.findIndex((r => r.id === item._id));
                    rows[rowIndex].status = status;
                    rows[rowIndex].changeStatusDate = moment(item.ChangeStatusDate).format("MM/DD/YYYY");
                    rows[rowIndex].approver = item.Approver;
                    if (status === "APPROVED" && self.props.hasApprovedOTField) {
                        rows[rowIndex].approvedHours = (item.ApprovedMinutes / 60).toFixed(2);
                    }
                    rows[rowIndex].approverRemarks = item.ApproverRemarks;
                });
                var msgStr = "";
                msgStr += message.hasOwnProperty("posMessage") ? message["posMessage"] : "";
                //msgStr += message.hasOwnProperty("errMessage") ? message["errMessage"] : "";
                if (message.hasOwnProperty("errMessage")) {
                    msgStr += message["errMessage"];
                    var conflictingIds = message["errData"];

                    // get rowIndexes;
                    var conflictingRowIndexes = rows.filter(row => conflictingIds.indexOf(row.id) !== -1)
                        .map((row) => rows.findIndex(r => r.id === row.id) + 1);
                    msgStr += " on row number" + (conflictingIds.length > 1 ? "s " : " ") + conflictingRowIndexes.map(id => id);
                }
                if (self.props.hasApprovedOTField) self.setState({ approvedOTHours: 0 });
                self.setState({ approverRemarks: "", rows: rows },
                    () => {
                        self.updateButtonsVisibility(self.messageBoxOkOnlyOpen(true, message["title"], msgStr));
                    }
                );
            },
            finally: function () {
            }
        };
        ajaxPost(parameter);
    }

    handleFilterClick = () => {
        // save current filter
        var filterPrevState = {
            showPending: this.state.showPending,
            showApproved: this.state.showApproved,
            showRejected: this.state.showRejected,
            showCancelled: this.state.showCancelled,
            includeDeleted: this.state.includeDeleted
        };
        this.setState({ showFiltersModal: true, filterPrevState });
    }

    cancelFilter = () => {
        var filterPrevState = this.state.filterPrevState;
        this.setState({
            showPending: filterPrevState.showPending,
            showApproved: filterPrevState.showApproved,
            showRejected: filterPrevState.showRejected,
            showCancelled: filterPrevState.showCancelled,
            includeDeleted: filterPrevState.includeDeleted,
            showFiltersModal: false
        });
    }

    downloadTemplate = () => {

        let title = this.props.title;
        let titleSingular = title === 'Official Businesses' ? title.substring(0, title.length - 2) : title;
        this.setState({ isLoading: true });
        ajaxPostDownload(
            this.props.urls.downloadTemplate,
            {},
            titleSingular + " Template.xlsx",
            () => {

            },
            (error) => {

            },
            () => {
                this.setState({ isLoading: false });
            }
        );
    }

    importFromExcel = () => {
        if (this.state.isLoading)
            return;

        fileDialog().then(file => {
            const data = new FormData();
            data.append('file', file[0]);
            data.append('RequestClass', JSON.stringify({
                SessionKey: 'HRIS_SessionId',
                SessionId: global.sessionId
            }));
            this.setState({ isLoading: true });
            fetch(global.ApiHost + this.props.urls.importFromExcel, {
                method: 'POST',
                credentials: 'include',
                body: data
            }).then((resp) => {
                this.setState({ isLoading: false });
                return resp.json();
            }).then((json) => {
                var data = JSON.parse(json.JsonData);
                var hasErrors = data.hasOwnProperty("errors") && data.errors.length > 0;
                // TODO alert(msg);
                this.messageBoxOkOnlyOpen(true, this.props.title, !hasErrors ? data.content : <div><p>{data.content}</p>{data.errors.map(err => { return (<span>{err}<br /></span>) })}</div>);
                this.setState({ isLoading: false });
            })
        });
    }

    exportToExcel = () => {
        var fromDate = this.state.fromDate;
        var toDate = this.state.toDate;

        var checkDateParams = checkDateRange(fromDate, toDate)
        if (!checkDateParams.Result) {
            this.messageBoxOkOnlyOpen(true, checkDateParams.Title, checkDateParams.Message);
            fromDate = this.state.validFromDate;
            toDate = this.state.validToDate;
            this.setState({ fromDate, toDate });
            return;
        }

        if (this.state.rows.length === 0) return;
        this.setState({ isLoading: true });
        var statuses = [];
        if (this.state.showPending) statuses.push("PENDING");
        if (this.state.showApproved) statuses.push("APPROVED");
        if (this.state.showRejected) statuses.push("REJECTED");
        if (this.state.showCancelled) statuses.push("CANCELLED");
        let employeeIds = this.state.selectedEmployees;

        let parameter = {
            employeeIds: employeeIds,
            fromDate: moment(fromDate).toJSON(),
            toDate: moment(toDate).toJSON(),
            statuses: statuses,
            includeDeleted: this.state.includeDeleted,
            requestorId: this.state.userId,
            //startIndex: (this.state.activePage - 1) * this.state.itemsPerPage,
            //itemCount: this.state.itemsPerPage
        }
        let title = this.props.title;
        let titleSingular = title === 'Official Businesses' ? title.substring(0, title.length - 2) : title;
        ajaxPostDownload(
            this.props.urls.exportToExcel,
            parameter,
            titleSingular + " Requests.xlsx", // title
            () => {
                //this.messageBoxOkOnlyOpen(true, "Success", "Export successful");
            },
            (error) => {
                var errorMessage = "An error has occurred";
                if (error) {
                    let errorData = JSON.stringify(error);
                    errorMessage += errorData !== "{}" ? "\r\n" + errorData : "";
                }
                this.messageBoxOkOnlyOpen(true, "Error", errorMessage);
            },
            () => {
                this.setState({ isLoading: false });
            }
        )
    }

    isNullOrEmpty(val) {
        if (val === null || val === undefined || val === "" || val.length === 0) return true;
        else return false;
    }

    handlePopup(event, { taggedId }) {
        var name = "open" + taggedId;
        // w3-hide-small	Hide content on small screens (less than 601px)
        // w3-hide-medium	Hide content on medium screens
        // w3-hide-large	Hide content on large screens (larger than 992px)
        this.setState({
            [name]:
                event.type === "mouseenter" && window.innerWidth < 992
        });
    }

    rowKeyGetter(row) {
        return row.id;
    }

    render() {
        // let globalObject = global;
        // let userIdGlobal = global.userId;
        // let sessionIdGlobal = global.sessionId;
        let self = this;
        let statusOptions = this.state.statusOptions;
        let userId = this.state.userId;
        let hasHrAccess = this.state.hasHrAccess;
        let hasStatusField = this.state.hasStatusField;
        let userRightsKeys = this.props.userRightsKeys;
        let actionableCount =
            Number(isTaskAvailable(userRightsKeys.create)) +
            Number(isTaskAvailable(userRightsKeys.edit) && window.innerWidth >= 992) +
            //Number(isTaskAvailable(userRightsKeys.edit)) +
            Number(isTaskAvailable(userRightsKeys.delete)) +
            Number(isTaskAvailable(userRightsKeys.approve) && hasStatusField) +
            Number(isTaskAvailable(userRightsKeys.reject) && hasStatusField) +
            Number(isTaskAvailable(userRightsKeys.cancel) && hasStatusField) +
            (Number(isTaskAvailable(userRightsKeys.export)) || Number(isTaskAvailable(userRightsKeys.import)));
        let actionableCountRatio = (1 / actionableCount).toFixed(2) + " %";

        var arrSelected = Array.from(this.state.selectedRowIds);


        return (
            <div className={modStyles.container}>
                <Header as='h2'>{this.props.title}</Header>
                <div style={{ marginTop: '20px' }}>
                    <div className='w3-row'>
                        {hasStatusField &&
                            <div className='w3-hide-small w3-col l6'>
                                <Button name='pending' size='mini' active={this.state.activeStatus === 'pending'} onClick={this.handleStatusClick} toggle basic>Pending</Button>
                                <Button name='approved' size='mini' active={this.state.activeStatus === 'approved'} onClick={this.handleStatusClick} toggle basic>Approved</Button>
                                <Button name='cancelled' size='mini' active={this.state.activeStatus === 'cancelled'} onClick={this.handleStatusClick} toggle basic>Cancelled</Button>
                                <Button name='rejected' size='mini' active={this.state.activeStatus === 'rejected'} onClick={this.handleStatusClick} toggle basic>Rejected</Button>
                                <Button name='all' size='mini' active={this.state.activeStatus === 'all'} onClick={this.handleStatusClick} toggle basic>All</Button>
                            </div>}
                        <div className={hasStatusField ? 'w3-hide-small w3-col l6' : 'w3-hide-small w3-col l12'}>
                            <Pagination
                                activePage={this.state.activePage}
                                defaultActivePage={1}
                                pointing
                                secondary
                                //ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                size='mini'
                                siblingRange={2}
                                boundaryRange={0}
                                totalPages={this.state.totalPages}
                                onPageChange={this.handlePageChange.bind(this)}
                                floated='right'
                            />
                        </div>
                        <div className={hasStatusField ? 'w3-hide-medium w3-hide-large w3-col l6' : 'w3-hide-medium w3-hide-large w3-col l12'}>
                            <Pagination
                                activePage={this.state.activePage}
                                defaultActivePage={1}
                                pointing
                                secondary
                                //ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                size='mini'
                                siblingRange={1}
                                boundaryRange={0}
                                totalPages={this.state.totalPages}
                                onPageChange={this.handlePageChange.bind(this)}
                                floated='right'
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <div className='w3-row' style={{ width: '100%', minHeight: '50px', marginTop: '10px' }} fitted='horizontally'>
                        <div className='w3-col l3'>
                            <table style={{ width: '100%', marginTop: '10px' }}>
                                <tr>
                                    {isTaskAvailable(userRightsKeys.create) &&
                                        <td style={{ width: actionableCountRatio }}>
                                            <Popup trigger={
                                                <Button
                                                    className='w3-hide-small w3-hide-medium'
                                                    fluid
                                                    size="mini"
                                                    iconPosition="top"
                                                    icon='plus'
                                                    onClick={this.btnAdd_click}
                                                >
                                                </Button>
                                            } inverted content='Create New' />
                                            <Button
                                                className='w3-hide-large'
                                                size="mini"
                                                iconPosition="top"
                                                icon="plus"
                                                content='Create New'
                                                fluid
                                                onClick={this.btnAdd_click}
                                            />
                                        </td>
                                    }
                                    {isTaskAvailable(userRightsKeys.edit) &&
                                        <td style={{ width: actionableCountRatio }}>
                                            <Popup trigger={
                                                <Button
                                                    className='w3-hide-small w3-hide-medium'
                                                    fluid
                                                    size="mini"
                                                    iconPosition="top"
                                                    icon="edit"
                                                    onClick={this.btnEdit_Click.bind(this, this.state.rows.find(row => row.id == arrSelected[0]))}
                                                    disabled={!this.state.showEditButton}
                                                />
                                            } inverted content='Edit' />
                                        </td>
                                    }
                                    {isTaskAvailable(userRightsKeys.approve) && hasStatusField &&
                                        <td style={{ width: actionableCountRatio }}>
                                            <Popup trigger={
                                                <Button
                                                    className='w3-hide-small w3-hide-medium'
                                                    fluid
                                                    size="mini"
                                                    iconPosition="top"
                                                    icon="thumbs up"
                                                    taggedStatus="APPROVED"
                                                    onClick={this.btnChangeStatus_Click.bind(this)}
                                                    // onClick={() => { 
                                                    //     this.setState({ selectedStatus: "APPROVED" },
                                                    //         () => { this.btnChangeStatus_Click.bind(this) } ) 
                                                    //     } 
                                                    // }
                                                    disabled={!this.state.showApproveButton}
                                                />
                                            } inverted content='Approve' />
                                            <Button
                                                className='w3-hide-large'
                                                size="mini"
                                                iconPosition="top"
                                                icon="thumbs up"
                                                content='Approve'
                                                fluid
                                                taggedStatus="APPROVED"
                                                onClick={this.btnChangeStatus_Click.bind(this)}
                                                disabled={!this.state.showApproveButton}
                                            />
                                        </td>
                                    }
                                    {isTaskAvailable(userRightsKeys.reject) && hasStatusField &&
                                        <td style={{ width: actionableCountRatio }}>
                                            <Popup trigger={
                                                <Button
                                                    className='w3-hide-small w3-hide-medium'
                                                    fluid
                                                    size="mini"
                                                    icon="thumbs down"
                                                    taggedStatus="REJECTED"
                                                    onClick={this.btnChangeStatus_Click.bind(this)}
                                                    disabled={!this.state.showRejectButton}
                                                />
                                            } inverted content='Reject' />
                                            <Button
                                                className='w3-hide-large'
                                                fluid
                                                size="mini"
                                                icon="thumbs down"
                                                content='Reject'
                                                taggedStatus="REJECTED"
                                                onClick={this.btnChangeStatus_Click.bind(this)}
                                                disabled={!this.state.showRejectButton}
                                            />
                                        </td>
                                    }
                                    {isTaskAvailable(userRightsKeys.cancel) && hasStatusField &&
                                        <td style={{ width: actionableCountRatio }}>
                                            <Popup trigger={
                                                <Button
                                                    className='w3-hide-small w3-hide-medium'
                                                    size="mini"
                                                    icon="ban"
                                                    fluid
                                                    taggedStatus="CANCELLED"
                                                    onClick={this.btnChangeStatus_Click.bind(this)}
                                                    disabled={!this.state.showCancelButton}
                                                />
                                            } inverted content='Cancel' />
                                            <Button
                                                fluid
                                                className='w3-hide-large'
                                                size="mini"
                                                icon="ban"
                                                content='Cancel'
                                                taggedStatus="CANCELLED"
                                                onClick={this.btnChangeStatus_Click.bind(this)}
                                                disabled={!this.state.showCancelButton}
                                            />
                                        </td>
                                    }
                                    {isTaskAvailable(userRightsKeys.delete) &&
                                        <td style={{ width: actionableCountRatio }}>
                                            <Popup className='w3-hide-small w3-hide-medium' trigger={
                                                <Button
                                                    className='w3-hide-small w3-hide-medium'
                                                    fluid
                                                    size="mini"
                                                    iconPosition="top"
                                                    icon="delete"
                                                    onClick={() => { this.setState({ showDeleteModal: true }); }}
                                                    disabled={!this.state.showDeleteButton}
                                                />
                                            } inverted content='Delete' />
                                            <Button
                                                className='w3-hide-large'
                                                fluid
                                                size="mini"
                                                iconPosition="top"
                                                icon="delete"
                                                content='Delete'
                                                onClick={() => { this.setState({ showDeleteModal: true }); }}
                                                disabled={!this.state.showDeleteButton}
                                            />
                                        </td>
                                    }
                                    {(isTaskAvailable(userRightsKeys.export) || isTaskAvailable(userRightsKeys.import)) &&
                                        <td style={{ width: actionableCountRatio }}>
                                            <ButtonGroup size='mini' fluid>
                                                <Dropdown button floating direction='left' icon='chevron down' className='icon'>
                                                    <Dropdown.Menu>
                                                        {isTaskAvailable(userRightsKeys.import) &&
                                                            <Popup className='w3-hide-small w3-hide-medium' trigger={
                                                                <Button
                                                                    className='w3-hide-small w3-hide-medium'
                                                                    fluid
                                                                    size="mini"
                                                                    iconPosition="top"
                                                                    icon="download"
                                                                    onClick={this.downloadTemplate}
                                                                />
                                                            } inverted content='Download Template' />
                                                        }
                                                        {isTaskAvailable(userRightsKeys.import) &&
                                                            <Button
                                                                className='w3-hide-large'
                                                                fluid
                                                                size="mini"
                                                                iconPosition="top"
                                                                icon="download"
                                                                content='Download Template'
                                                                onClick={this.downloadTemplate}
                                                            />
                                                        }
                                                        {isTaskAvailable(userRightsKeys.import) &&
                                                            <Popup className='w3-hide-small w3-hide-medium' trigger={
                                                                <Button
                                                                    className='w3-hide-small w3-hide-medium'
                                                                    fluid
                                                                    size="mini"
                                                                    iconPosition="top"
                                                                    icon="upload"
                                                                    onClick={this.importFromExcel}
                                                                />
                                                            } inverted content='Import from Excel' />
                                                        }
                                                        {isTaskAvailable(userRightsKeys.import) &&
                                                            <Button
                                                                className='w3-hide-large'
                                                                fluid
                                                                size="mini"
                                                                iconPosition="top"
                                                                icon="upload"
                                                                content='Import from Excel'
                                                                onClick={this.importFromExcel}
                                                            />
                                                        }
                                                        {isTaskAvailable(userRightsKeys.export) &&
                                                            <Popup className='w3-hide-small w3-hide-medium' trigger={
                                                                <Button
                                                                    className='w3-hide-small w3-hide-medium'
                                                                    fluid
                                                                    size="mini"
                                                                    iconPosition="top"
                                                                    icon="file excel outline"
                                                                    onClick={this.exportToExcel}
                                                                    disabled={this.state.rows.length === 0}
                                                                />
                                                            } inverted content='Export to Excel' />
                                                        }
                                                        {isTaskAvailable(userRightsKeys.export) &&
                                                            <Button
                                                                className='w3-hide-large'
                                                                fluid
                                                                size="mini"
                                                                iconPosition="top"
                                                                icon="file excel outline"
                                                                content='Export to Excel'
                                                                onClick={this.exportToExcel}
                                                                disabled={this.state.rows.length === 0}
                                                            />
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </ButtonGroup>
                                        </td>
                                    }
                                </tr>
                            </table>

                        </div>

                        <div className='w3-col l5' fitted='horizontally'>
                            <div className='w3-col m6 l6' style={{ paddingTop: '10px' }}>
                                <Input
                                    autoFocus
                                    required
                                    fluid
                                    size="mini"
                                    type='date'
                                    label='Start Date'
                                    min={minDate} max={maxDate}
                                    value={this.state.fromDate}
                                    error={!checkDate(this.state.fromDate).Result}
                                    style={{ marginRight: 3, minWidth: '50px', width: '100%', padding: '0px 5px 0px 0px' }}
                                    onChange={(evt) => this.setState({ fromDate: evt.target.value })}
                                />
                            </div>
                            <div className='w3-col m6 l6' style={{ paddingTop: '10px' }}>
                                <Input
                                    required
                                    fluid
                                    size="mini"
                                    type='date'
                                    label='End Date'
                                    min={minDate} max={maxDate}
                                    value={this.state.toDate}
                                    style={{ marginRight: 3, minWidth: '50px', width: '100%', padding: '0px 5px 0px 0px' }}
                                    error={!checkDate(this.state.toDate).Result}
                                    onChange={(evt) => this.setState({ toDate: evt.target.value })}
                                />
                            </div>
                        </div>
                        <div className='w3-col l4'>
                            {this.state.hasStatusField &&
                                <div className='w3-col l1' style={{ display: 'inline-block', paddingTop: '10px' }}>
                                    <Button
                                        className='w3-hide-small w3-hide-medium'
                                        floated='right'
                                        fluid
                                        size="mini"
                                        icon='filter'
                                        onClick={() => { this.setState({ showFiltersModal: true }) }}
                                        style={{ maxWidth: '30px', marginLeft: '3px', marginRight: '3px' }}
                                    />
                                    <Button
                                        className='w3-hide-large'
                                        fluid
                                        size="mini"
                                        icon='filter'
                                        onClick={() => { this.setState({ showFiltersModal: true }) }}
                                        content='Filter'
                                    />
                                </div>
                            }
                            <div className={'w3-hide-small w3-hide-medium w3-col l' + (10 - this.state.hasStatusField)} style={{ paddingTop: '10px', paddingLeft: '3px', paddingRight: '3px' }} fitted='horizontally'>
                                <Menu secondary size='mini' style={{ width: '100%' }}>
                                    <div style={{ width: '100%' }}>
                                        <SelectEmployees
                                            Ids={this.state.selectedEmployees}
                                            update={(Ids) => this.setState({ selectedEmployees: Ids !== null ? Ids : [] },
                                                () => { this.search(); }
                                            )}
                                            style={{ minWidth: '50px', maxWidth: '100%', width: '100%' }}
                                        />
                                    </div>
                                </Menu>
                            </div>
                            <div className='w3-hide-small w3-hide-medium w3-col l2' style={{ paddingTop: '10px' }} >
                                <Button floated='left' size='mini' fluid onClick={this.btnSearch_click} style={{ paddingLeft: '3px', paddingRight: '3px' }}> Search</Button>
                            </div>
                            <div className='w3-hide-large w3-col l10' style={{ paddingTop: '10px', paddingLeft: '3px', paddingRight: '3px' }} fitted='horizontally'>
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        <td style={{ width: '90%' }}>
                                            <Menu secondary size='mini' style={{ width: '100%' }}>
                                                <div style={{ width: '100%' }}>
                                                    <SelectEmployees
                                                        Ids={this.state.selectedEmployees}
                                                        update={(Ids) => this.setState({ selectedEmployees: Ids !== null ? Ids : [] },
                                                            () => { this.search(); }
                                                        )}
                                                        style={{ minWidth: '50px', maxWidth: '100%', width: '100%' }}
                                                    />
                                                </div>
                                            </Menu>
                                        </td>
                                        <td style={{ width: '10%' }}>
                                            <Button className='w3-hide-medium w3-hide-large' fluid size='mini' icon='search' type='submit' onClick={this.btnSearch_click} />
                                            <Button className='w3-hide-small' fluid size='mini' type='submit' style={{ 'max-height': '31px', marginLeft: '6px' }} onClick={this.btnSearch_click}> Search</Button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <Loader size="big" active={this.state.isLoading}></Loader>

                <DataGrid
                    ref={node => this.grid = node}
                    enableCellSelect={true}
                    columns={this.props.columns}
                    //rowGetter={this.rowGetter}
                    rows={this.state.rows}
                    rowsCount={this.state.rows.length}
                    selectedRows={this.state.selectedRowIds}
                    onSelectedRowsChange={this.onRowsSelected}
                    minHeight={this.state.gridHeight}
                    rowSelection={'multiple'}
                    rowKeyGetter={this.rowKeyGetter}
                />


                <Modal open={this.state.showFiltersModal} size="mini">
                    <Modal.Header>
                        Search Filters
                    </Modal.Header>
                    <Modal.Content>
                        <div>
                            {this.state.hasStatusField &&
                                <div class="ui grid">
                                    <div class="sixteen wide column">
                                        <p>Application Status</p>
                                    </div>
                                    <div class="left floated eight wide column">
                                        <Checkbox checked={this.state.showPending} onChange={() => { this.setState({ showPending: !this.state.showPending }) }} label='Pending' />
                                    </div>
                                    <div class="right floated eight wide column">
                                        <Checkbox checked={this.state.showApproved} onChange={() => { this.setState({ showApproved: !this.state.showApproved }) }} label='Approved' />
                                    </div>
                                    <div class="left floated eight wide column">
                                        <Checkbox checked={this.state.showRejected} onChange={() => { this.setState({ showRejected: !this.state.showRejected }) }} label='Rejected' />
                                    </div>
                                    <div class="right floated eight wide column">
                                        <Checkbox checked={this.state.showCancelled} onChange={() => { this.setState({ showCancelled: !this.state.showCancelled }) }} label='Cancelled' />
                                    </div>
                                </div>
                            }
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic icon='save' content="Save" onClick={() => { this.setState({ showFiltersModal: false }, this.search()) }}></Button>
                        <Button basic icon='cancel' content="Cancel" onClick={this.cancelFilter} />
                    </Modal.Actions>
                </Modal>

                {this.state.showDeleteModal &&
                    <MessageBoxYesNo
                        title="Delete Transaction"
                        caption={"Are you sure you want to delete transaction" + (this.state.selectedRowIds.size > 1 ? "s" : "") + "?"}
                        action="Yes"
                        CancelCaption="No"
                        onAction={() => { this.setDeleted(true) }}
                        onClose={() => { this.setState({ showDeleteModal: false }) }} />
                }

                {this.state.showRestoreModal &&
                    <MessageBoxYesNo
                        title="Restore Transaction"
                        caption={"Restore selected transaction" + (this.state.selectedIndices.length > 1 ? "s" : "") + "?"}
                        action="Yes"
                        CancelCaption="No"
                        onAction={() => { this.setDeleted(false) }}
                        onClose={() => { this.setState({ showRestoreModal: false }) }} />
                }
                {this.state.showCancelModal &&
                    <MessageBoxYesNo
                        title="Cancel Transaction"
                        caption={"Cancel selected transaction request" + (this.state.selectedIndices.length > 1 ? "s" : "") + "?"}
                        action="Yes"
                        CancelCaption="No"
                        onAction={() => { this.setCancelled(false) }}
                        onClose={() => { this.setState({ showCancelModal: false }) }} />
                }
                {this.state.messageBoxOkOnly.show &&
                    <MessageBoxOkOnly
                        title={this.state.messageBoxOkOnly.title}
                        caption={this.state.messageBoxOkOnly.caption}
                        onClick={() => { this.messageBoxOkOnlyOpen(false); }}
                    />
                }


                {
                    statusOptions.length > 0 &&
                    <Modal size="mini" open={this.state.statusFormVisible}>
                        <Modal.Header>
                            {this.state.selectedStatus === "APPROVED" ? "Approve" : this.state.selectedStatus === "REJECTED" ? "Reject" : "Cancel"}
                            {" selected transaction" + (this.state.selectedIndices.length > 1 ? "s" : "") + "?"}
                        </Modal.Header>
                        <Modal.Content>
                            <div className="ui one column grid">
                                {
                                    this.props.hasApprovedOTField &&
                                    this.state.selectedStatus === "APPROVED" &&
                                    this.state.selectedRowIds.size === 1 &&
                                    // <div className="ui one column grid" style={noMarginNoPadding}>
                                    <div className="column">
                                        <div>Employee</div>
                                        <Header size='small' style={{ "margin": "0" }}>{this.state.rows[this.state.selectedIndices[0]].employee}</Header>
                                    </div>
                                    // </div>
                                }
                                {
                                    this.props.hasApprovedOTField &&
                                    this.state.selectedStatus === "APPROVED" &&
                                    this.state.selectedRowIds.size === 1 &&
                                    <div className="column">
                                        <div>Approved OT Hours</div>
                                        <Input fluid value={this.state.approvedOTHours}
                                            onChange={(event) => { self.setState({ approvedOTHours: handleNumeric(event) }); }}
                                        />
                                    </div>
                                }
                                <div className="column">
                                    <div>Remarks</div>
                                    <Input fluid value={this.state.approverRemarks} onChange={(evt) => { this.setState({ approverRemarks: evt.target.value }); }} />
                                </div>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button basic icon='check' content="OK" onClick={
                                () => {
                                    this.setState({ statusFormVisible: false }, this.onChangeStatus.bind(this));
                                }
                            }></Button>
                            <Button basic icon='cancel' content="Cancel" onClick={
                                () => {
                                    this.setState({ statusFormVisible: false });
                                }
                            }></Button>
                        </Modal.Actions>
                    </Modal>
                }

                {this.props.children}

            </div>
        );
    }

}

TransactionModuleBase.propTypes = {
    viewID: PropTypes.string,
    title: PropTypes.string,
    toRow: PropTypes.func,
    toModel: PropTypes.func,
    columns: PropTypes.func,
    urls: PropTypes.func,
    validate: PropTypes.func,
    props: PropTypes.object,
    showModal: PropTypes.func,
    userRightsKeys: PropTypes.func,
    hasStatusField: PropTypes.bool,
    hasExportToExcel: PropTypes.bool,
    exportToExcel: PropTypes.func
}

export default TransactionModuleBase;