import React, { Component } from "react";
import {
  Modal,
  Button,
  Image,
  Message,
} from "../../node_modules/semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { ajaxPost } from "../ajax";
import { isTaskAvailable } from "../utils";

var info = require('./../buildInfo.json');


class AboutDialog extends Component {
  state = {
    registeredEmpCount: 10,
    softwareStatus: "",
  };

  componentDidMount() {
    ajaxPost({
      url: "api/License/getProductKeyInfo",
      data: {},
      onSuccess: (data) => {
        if (data != null) {
          this.setState({ dateAcquired: data["DateAcquired"] });
          this.setState({ registeredEmpCount: data["EmpCount"] });
        } else {
          this.setState({ registeredEmpCount: 10 });
        }
        this.setState({ softwareStatus: data["SoftwareStatus"] });
      },
      finally: () => { },
    });
  }

  handleClose = () => {
    this.props.onClick();
  };

  render() {

    var coyId = localStorage.getItem('company_id');
    var link = `https://${coyId}.zkpayroll.com`

    return (
      <Modal open size="tiny" onClose={this.handleClose}>
        <Modal.Content>
          <br />
          <Image
            src={global.logo_url}
            style={{ height: "45px", width: "160px" }}
          />
          <br />
          <Modal.Description>
            <label>Version&nbsp;{info.version}</label>
            <br />
            <label>Database Ver.&nbsp;{info.dbVersion}</label>
            <br />
            <br />
            <label><b>{this.state.softwareStatus}</b></label>
            <br />
            <label>
              Maximum Employee Count:&nbsp;{this.state.registeredEmpCount}
            </label>
            <div>
              Company Code:&nbsp;{coyId}
            </div>
            <div>
              Access Link:&nbsp;<a href={link}>{link}</a>
            </div>
            <br />
            <br />
            {this.state.softwareStatus === "ACTIVATED"
              ? isTaskAvailable("VIEW_LICENSE") && (
                  <Button
                    positive
                    size="small"
                    onClick={() => {
                      this.props.onUpgrade();
                    }}
                  >
                    Upgrade
                  </Button>
                )
              : isTaskAvailable("VIEW_LICENSE") && (
                  <Button
                    positive
                    size="small"
                    onClick={() => {
                      this.props.onUpgrade();
                    }}
                  >
                    Activate
                  </Button>
                )}
            {!isTaskAvailable("VIEW_LICENSE") && (
              <Message warning>No Access to Product License. Please contact your administrator.</Message>
            )}
            <br />
            <br />
            <label>
              Website:&nbsp;<a href="https://payroll.zkteco.ph/ZKTecoPayroll" target="_blank">https://payroll.zkteco.ph/zktecopayroll</a>
            </label>
            <br />
            <label>
              Support:&nbsp;<a href="mailto:support@zkteco.ph">support@zkteco.ph</a>
            </label>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button basic content="OK" onClick={this.handleClose}></Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default AboutDialog;
